.root {
  margin-top: 90px;
}

.mainContiner {
  margin: 20px 15px 10px 15px;
}

.buttonsWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.report_heading1 {
  font-weight: 700;
  font-size: 22px;
  color: #1a3353;
  width: 350px;
}

.report_heading2 {
  font-weight: 400;
  font-size: 14px;
  color: #1a3353;
  width: 350px;
}

.report_heading3 {
  font-weight: 400;
  font-size: 22px;
  color: #455560;
  display: flex;
  justify-content: space-between;
  width: 350px;
}

.report_heading4 {
  font-weight: 300;
  font-size: 18px;
  color: #455560;
  margin-top: 15px;
  width: 350px;
}

.reportTitle {
  text-align: center;
}
.heading5 {
  color: #1a3353;
  font-weight: 700;
  font-size: 34px;
}
.heading6 {
  font-weight: 400;
  font-size: 22px;
  color: #1a3353;
}
.heading7 {
  font-weight: 500;
  font-size: 17px;
  color: #1a3353;
}
.capitalize_text {
  text-transform: capitalize;
}

.report_cover_image {
  height: 280px;
  width: 350px;
  max-width: 100%;
  object-fit: cover;
  margin: 10px 0px;
}
.goBackWrapper {
  margin-left: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #3e79f7;
}

.titleWrapper {
  margintop: 25px;
  text-align: center;
}

.rightButton {
  text-transform: uppercase;
}

.leftButton {
  text-transform: uppercase;
  margin-right: 10px;
}

@media screen and (max-width: 800px) {
  .report_cover_image {
    width: 300px;
  }
  .report_heading1 {
    width: 300px;
  }

  .report_heading2 {
    width: 300px;
  }

  .report_heading3 {
    width: 300px;
  }
  .report_heading4 {
    width: 300px;
  }
}
@media screen and (max-width: 700px) {
  .report_cover_image {
    width: 250px;
    height: 250px;
  }
  .report_heading1 {
    width: 250px;
  }

  .report_heading2 {
    width: 250px;
  }

  .report_heading3 {
    width: 250px;
  }
  .report_heading4 {
    width: 250px;
  }
}
@media screen and (max-width: 600px) {
  .report_cover_image {
    width: 98%;
    height: 150px;
  }
  .report_heading1 {
    width: 98%;
    font-size: 12px;
  }

  .report_heading2 {
    width: 98%;
    font-size: 12px;
  }

  .report_heading3 {
    width: 98%;
    font-size: 12px;
  }
  .report_heading4 {
    width: 98%;
    font-size: 10px;
  }
}

@media screen and (min-width: 1200px) {
  .report_cover_image {
    width: 500px;
    height: 300px;
  }
  .report_heading1 {
    width: 500px;
    font-size: 22px;
  }

  .report_heading2 {
    width: 500px;
  }

  .report_heading3 {
    width: 500px;
    font-size: 22px;
  }
  .report_heading4 {
    width: 500px;
    font-size: 18px;
  }
}
@media screen and (min-width: 1600px) {
  .goBackWrapper {
    font-size: 23px;
  }
  .leftButton {
    font-size: 21px;
    height: 52px;
  }
  .rightButton {
    font-size: 21px;
    height: 52px;
  }
  .heading5 {
    font-size: 38px;
  }
  .heading6 {
    font-size: 28px;
  }
  .heading7 {
    font-size: 25px;
  }
  .report_heading1 {
    font-size: 30px;
  }
  .report_heading2 {
    font-size: 22px;
  }
  .report_heading3 {
    font-size: 30px;
  }
  .report_heading4 {
    font-size: 26px;
  }
}
