.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #3e79f7;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
}

.new-feature {
  width: 100%;
  text-align: center;
}

.new-feature-img {
  object-fit: cover;
  max-width: 400px;
  height: auto;
  margin: 50px auto;
}

.hidmyMenu {
  display: none;
}

.sidenav-filters {
  padding: 8px 16px 0 16px;
}

.sidenav-filters--div1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.sidenav-filters--div2 {
  margin-bottom: 15px;
}

.sidenav-filters--div3 {
  margin-top: 8px;
}

.sidenav-filters--div4 {
  margin-top: 40px;
}

.sidenav-filters--div5 {
  margin-top: 45px;
  display: flex;
}

.sidenav-filters--div6 {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}

.sidenav-filters--div7 {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
}

.sidenav-filters--div8 {
  margin-top: 5px;
}

.sidenav-filters--div9 {
  margin-top: 20px;
}

.sidenav-filters--div10 {
  display: flex;
  margin-bottom: 10px;
}

.sidenav-input-wrapper {
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.availability-render-div {
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
}

.sidenav-scrollbar {
  height: 200px;
  overflow-y: scroll;
}

.sidenav-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.sidenav-filter-button {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  color: rgba(26, 51, 83, 0.6);
  text-transform: uppercase;
}

.sidenav-reset-button {
  font-size: 12px;
  text-transform: uppercase;
  color: rgba(26, 51, 83, 0.6);
  font-weight: 700;
  font-style: normal;
}

/* Track */
.sidenav-scrollbar::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 100px;
}

/* Handle */
.sidenav-scrollbar::-webkit-scrollbar-thumb {
  background: #3e79f7;
  border-radius: 100px;
}

.sidenav-heading1 {
  font-weight: 500;
  color: #1a3353;
  font-size: 14px;
  font-style: normal;
  margin-bottom: 3px;
}

.sidenav-slider-values {
  color: #4f4f4f;
  font-weight: 400;
  font-size: 12px;
}

.sidenav-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.sidenav-heading2 {
  font-weight: 500;
  color: #1a3353;
  font-size: 14px;
  font-style: normal;
  margin: 16px 16px;
}

.sidenav-title {
  font-weight: 500;
  color: #1a3353;
  font-size: 14px;
  font-style: normal;
}
.sidenav-title-thin {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-style: normal;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/*Home*/

/*Property Database*/

.property-database-wrapper {
  background-color: #fff;
  height: auto;
  position: relative;
  z-index: 2;
  width: 100%;
  border: 1px solid #e6ebf1;
  border-radius: 10px;
  padding: 20px;
}

.reports-wrapper {
  background-color: #fff;
  position: relative;
  z-index: 2;
  width: 100%;
  border: 1px solid #e6ebf1;
  border-radius: 10px;
  padding: 20px;
}

/*Heading*/

.property-database-heading-wrapper {
  margin-left: 15px;
}

.property-database-heading1 {
  font-weight: 500;
  color: #1a3353;
  font-size: 22px;
  font-style: normal;
}

.collection-title {
  font-weight: 500;
  color: #1a3353;
  font-size: 22px;
  font-style: normal;
}

.property-database-heading2 {
  font-weight: 400;
  color: #1a3353;
  font-size: 14px;
  font-style: normal;
  margin-top: 2px;
}

.property-database-heading3 {
  font-weight: 700;
  color: #1a3353;
  font-size: 14px;
  font-style: normal;
  text-transform: uppercase;
}

.property-database-heading4 {
  font-weight: 700;
  color: #1a3353;
  font-size: 21px;
  font-style: normal;
}

.property-database-heading5 {
  font-weight: 400;
  color: #1a3353;
  font-size: 14px;
  font-style: normal;
}

.property-database-heading-red {
  font-weight: 400;
  color: red;
  font-size: 14px;
  font-style: normal;
}

.property-database-heading6 {
  font-weight: 500;
  color: #1a3353;
  font-size: 22px;
  font-style: normal;
}

.property-database-heading7 {
  font-weight: 700;
  color: #1a3353;
  font-size: 21px;
  font-style: normal;
}

.property-database-heading8 {
  font-weight: 400;
  color: #1a3353;
  font-size: 14px;
  font-style: normal;
}

.property-database-heading9 {
  font-weight: 400;
  color: #3e79f7;
  font-size: 14px;
  font-style: normal;
}

.property-database-heading10 {
  font-weight: 700;
  color: #1a3353;
  font-size: 30px;
  font-style: normal;
}

.parse-heading-class {
  color: #1a3353 !important;
}

.parse-heading-class > p {
  font-weight: 400;
  color: #1a3353;
  font-size: 14px;
  font-style: normal;
}

.property-database-exlinks > a > p {
  font-weight: 400;
  color: #1a3353;
  font-size: 14px;
  font-style: normal;
  text-decoration: underline;
}

.property-database-button1 {
  width: 175px;
  height: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
}

.property-database-button2 {
  margin: 27px 0 0 10px;
  width: 176px;
  height: 67px;
  font-style: normal;
  font-size: 14px;
  color: #1a3353;
}

.property-database-button3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.property-database-button4 {
  margin: 27px 0 0 4px;
  width: 192px;
  height: 67px;
  font-size: 14px;
  color: #1a3353;
}

.property-database-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 15px;
}

.property-database-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.property-database-button-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.property-database-direction-icon {
  font-size: 9px;
  font-weight: 700;
  color: #1a3353;
}

.property-database-table-section2 {
  display: flex;
  flex-direction: column;
}

.property-details-div1 {
  display: flex;
  flex-direction: row;
}

.property-details-div1-sub1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.property-details-div1-sub2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.property-details-div2 {
  margin-top: 20px;
}

.property-details-div3 {
  margin-top: 30px;
}

.property-details-div4 {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.property-details-div4-sub {
  flex: 1;
  text-transform: capitalize;
}

.property-details-div4-sub > p {
  font-weight: 400;
  color: #1a3353;
  font-size: 14px;
  font-style: normal;
  margin-top: 5px;
}

.property-details-div5 {
  margin-top: 30px;
}

.property-details-div-agent {
  margin-top: 20px;
}

.property-details-div-agent > p {
  font-weight: 400;
  color: #1a3353;
  font-size: 14px;
  font-style: normal;
  margin-top: 5px;
}

/*Custom Modal*/
.custom-modal-div {
  display: flex;
  justify-content: flex-start;
}

.custom-modal-heading1 {
  font-size: 17px;
  font-weight: 700;
  color: #1a3353;
  font-style: normal;
}

.custom-modal-heading2 {
  font-size: 14px;
  font-weight: 400;
  color: #72849a;
  font-style: normal;
}

.custom-modal-icon {
  color: #ffc542;
  font-size: 18px;
  margin-top: 6px;
}

.edit-details-m-top {
  margin-top: 20px;
}

.collection-form-buttons-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

.collectionDetails-form-buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 25px;
}

.collection-form-button {
  width: 140px;
  height: 40px;
  text-transform: uppercase;
}

.button-mr {
  margin-right: 20px;
}

/*collection-form*/
.form-row {
  display: flex;
  margin-top: 20px;
}

.form-row-left-el {
  flex: 1;
  margin-right: 5%;
}

.form-row-right-el {
  flex: 1;
  margin-left: 5%;
}

.button-text {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
}

.agent-from-wrapper {
  margin-top: 70px;
}

.agent-from-button {
  margin-top: 40px;
}

.form-heading {
  font-weight: 500;
  color: #1a3353;
  font-size: 16px;
  font-style: normal;
}

/* Collection-details */

.collection-details-button {
  margin-left: 12px;
}

.comment-value {
  font-weight: 400;
  color: #455560;
  font-size: 14px;
  font-style: normal;
}

.collection-table-comment {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 15px;
}

.back-to-database {
  margin-top: 15px;
  cursor: pointer;
  color: #3e79f7;
  width: fit-content;
  font-size: 14px;
  font-weight: 400;
}

.text-format {
  text-transform: capitalize;
}

.loader-center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.logout-wrapper {
  margin-right: 50px;
  margin-top: 23px;
  cursor: pointer;
}

.logout-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.logout-heading:hover {
  color: #3e79f7;
}

.login-form-heading1 {
  font-size: 16px;
  font-weight: bold;
  margin-top: 2px;
}

.login-form-heading2 {
  font-size: 14px;
  margin-bottom: 15px;
  margin-top: 2px;
}

.login-form-button1 {
  margin-bottom: 0;
}

.image_placeholder {
  background: url(./assets/img/kw-logo-placeholder.png) no-repeat;
}

.carousel .thumb img {
  width: 150px !important;
  height: 80px !important;
}

.filed-error-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.close-collection-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 70px;
}

.close-collection-button {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
}

.image-des-heading {
  font-weight: 700;
  font-size: 14px;
  color: #1a3353;
  margin: 30px 0 20px 0;
}

.edit-details-img {
  height: 200px;
  object-fit: cover;
}

.range-slider {
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  display: block;
  position: relative;
  width: 100%;
  height: 8px;
  background: #ddd;
  border-radius: 4px;
}

.range-slider[data-vertical] {
  height: 100%;
  width: 8px;
}

.range-slider[data-disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.range-slider .range-slider__thumb {
  position: absolute;
  z-index: 3;
  top: 50%;
  width: 24px;
  height: 24px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #3e79f7;
}
.datepicker-dropdown {
  position: absolute;
  bottom: -10px;
  left: 0;
  z-index: 100;
  /* width: 100%; */
}

.range-slider .range-slider__thumb:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 6px rgba(33, 150, 243, 0.5);
}

.range-slider[data-vertical] .range-slider__thumb {
  left: 50%;
}

.range-slider .range-slider__thumb[data-disabled] {
  z-index: 2;
}

.range-slider .range-slider__range {
  position: absolute;
  z-index: 1;
  transform: translate(0, -50%);
  top: 50%;
  width: 100%;
  height: 100%;
  background: #699dff !important;
}
.range-slider .range-slider__thumb {
  background: white !important;
  border: 3px solid #699dff;
}

.range-slider[data-vertical] .range-slider__range {
  left: 50%;
  transform: translate(-50%, 0);
}

.tables-images {
  width: 200px;
  object-fit: cover;
  height: 132px;
}

.range-slider input[type="range"] {
  --webkit-appearance: none;
  pointer-events: none;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background-color: transparent;
}

.range-slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
}

.range-slider input[type="range"]::-moz-range-thumb {
  width: 0;
  height: 0;
  border: 0;
}

.range-slider input[type="range"]:focus {
  outline: 0;
}

/* Trend Report Section */

.new-trend-report-btn {
  width: 175px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-transform: uppercase;
  margin-top: 10px;
}
.new-trend-report-btn-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-transform: uppercase;
}
.new-report-divider-style {
  margin: 40px 0px 20px 0px;
}

.new-chart-btn {
  text-transform: uppercase;
}

.new-chart-btn-wrapper {
  margin: 30px 0px 50px 0px;
  text-align: right;
}
.new-report-form-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0px 20px 0px;
}

.new-report-conter-wrapper {
  margin-top: 15px;
}
.new-chart-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
.new-chart-heading {
  font-size: 21px;
  line-height: 28px;
  font-weight: 400;
  color: #1a3353;
}
.new-chart-input {
  margin-top: 20px;
  max-width: 50%;
  display: flex;
}
.new-chart-input-row {
  margin-top: 20px;
  display: flex;
}
.new-chart-contiue-btn-wrapper {
  margin-top: 20px;
  display: flex;
}
.btn-text-transform {
  text-transform: uppercase;
}
.add-new-chart-btn-wrapper {
  margin-top: 20px;
  padding-bottom: 30px;
  text-align: right;
  border-bottom: 1px solid #e6ebf1;
}
.modal-content {
  margin-top: 80px;
  padding: 0px 10px;
  text-align: center;
}

.chart-btn-wrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.chart-btn-div-1 {
  flex: 1;
}
.chart-btn-div-2 {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.chart-details-heading {
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;
  color: #000000;
}

.chart-details-heading::first-letter {
  text-transform: uppercase;
}

.chart-details-heading2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
}

.chart-styles {
  border: 1px solid #eff3f7;
  border-radius: 15px;
  padding: 20px;
  margin-top: 10px;
}

@media (max-width: 1000px) {
  .login-form-heading2 {
    margin-bottom: 25px;
  }

  .login-form-heading1 {
    margin-top: 5px;
  }

  .login-form-button1 {
    margin-bottom: 8px;
  }
}

@media (max-width: 775px) {
  .login-form-heading2 {
    margin-bottom: 20px;
  }

  .login-form-heading1 {
    margin-top: 5px;
  }

  .login-form-button1 {
    margin-bottom: 2px;
  }

  .edit-details-img {
    height: 150px;
  }
}

@media (min-width: 1600px) {
  .tables-images {
    width: 200px;
    object-fit: cover;
    height: 182px;
  }

  .modal-content {
    margin-top: 40px;
  }
  .property-database-heading4 {
    font-size: 32px;
  }

  .property-database-heading5 {
    font-size: 25px;
  }

  .property-database-heading-red {
    font-size: 24px;
  }

  .property-database-heading6 {
    font-size: 33px;
  }

  .property-database-heading7 {
    font-size: 32px;
  }

  .parse-heading-class > p {
    font-size: 25px;
  }

  .property-database-exlinks > a > p {
    font-size: 25px;
  }

  .property-details-div4-sub > p {
    font-size: 25px;
  }

  .property-database-heading8 {
    font-size: 25px;
  }

  .property-database-heading9 {
    font-size: 25px;
  }

  .property-database-heading10 {
    font-size: 37px;
  }

  .back-to-database {
    font-size: 23px;
    display: flex;
    align-items: center;
  }

  .property-details-div-agent > p {
    font-size: 25px;
  }

  .button-text {
    font-size: 21px;
    height: 52px;
  }

  .sidenav-heading1 {
    font-size: 22px;
  }

  .image-des-heading {
    font-size: 22px;
  }

  .property-database-button4 {
    height: 71px;
    font-size: 20px;
    width: 230px;
  }

  .property-database-button2 {
    height: 71px;
    font-size: 20px;
  }

  .close-collection-button {
    font-size: 20px;
    height: 52px;
  }

  .property-database-button1 {
    height: 52px;
    font-size: 20px;
    width: 210px;
  }

  .property-database-heading1 {
    font-size: 32px;
  }

  .property-database-heading2 {
    font-size: 25px;
  }

  .comment-value {
    font-size: 25px;
  }

  .sidenav-heading2 {
    font-size: 22px;
  }

  .sidenav-title {
    font-size: 22px;
  }

  .sidenav-filter-button {
    font-size: 17px;
  }

  .sidenav-reset-button {
    font-size: 17px;
  }

  .logout-heading {
    font-size: 22px;
  }

  .property-details-image {
    object-fit: cover;
    max-height: 800px;
  }
}
.menu-heading {
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  text-transform: uppercase;
  margin: 16px 16px;
  color: rgba(26, 51, 83, 0.6);
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  /* opacity: 0.3; */
  z-index: 1000;
}

.modal-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1010;
  background-color: #fff;
  width: 500px;
  height: 300px;
  border-radius: 10px;
}
